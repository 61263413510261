.header-form {
    background-color: #F2F2F2;
    margin-top: 57px;
    position: relative;

    .header-radials {
        position: absolute;
        height: 100%;

        @media only screen and (max-width: 991px) {
            & {
                width: 100%;;
            }
        }
    }

    .wrapper {
        padding-top: 18px;
        z-index: 1;
        .image-girl {
            background: url(/img/girl-min.png) no-repeat;
            display: block;
            background-blend-mode: darken;
            position: absolute;
            left: 642px;
            width: 1000px;
            top: 0;
            bottom: 0;
            background-size: cover;
            z-index: -1;
        }
        .img-girl {
            position: absolute;
            top: 0;
            left: 642px;
            //z-index: -1;
            bottom: 0;

            img {
                height: 100%;
                max-width: inherit;
            }
        }
        @media only screen and (max-width: 991px) {
            .image-girl {
                display: none;
            }
        }
    }
    .breadcrumbs {
        font-family: "Montserrat", sans-serif;
        font-weight: 500;
        font-size: 1.6rem;
        line-height: 2rem;
        color: #AAAAAA;
        margin-bottom: 10px;
        margin-top: 0;
    }
}

.catalog {
    font-family: Montserrat, sans-serif;
    padding-top: 7px;

    .title {
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 26px;
        color: #272727;
        display: flex;

        a {
            margin-left: 18px;
            padding: 5px 10px;
            display: flex;
            align-items: center;
            background: #F0F0F0;
            border-radius: 4px;
            font-weight: 600;
            font-size: 12px;
            line-height: 14px;
            color: #272727;
        }
        .icon-show-all {
            margin-left: 11px;
        }
    }

    .catalog-group {
        padding-bottom: 5px;

        .main-button {
            background: #C2818D;
            backdrop-filter: blur(2px);
            width: inherit;
            text-align: center;
            padding: 0 20px;
            border-radius: 4px;
            .text {
                line-height: 24px;
                font-weight: 400;
            }
            svg {
                margin-left: 10px;
                path {
                    fill: white;
                }
            }
        }
        .button-show-all {
            padding-bottom: 21px;
        }
    }

    .worksheets {
        display: flex;
        margin-top: 25px;
        //gap: 30px;

        & > div {
            //padding: 0;
        }

    }

    .text-all-in-nearest {
        padding-top: 12px;
    }
}
